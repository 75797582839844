import React, { useEffect } from "react";
import styled from "styled-components";
import CloseButton from "../CloseButton";
import { motion } from "framer-motion";

// import background4 from "../../assets/images/ui/background4.png";
import wishBackground from "../../assets/images/ui/wish-background.jpeg";

import { useDataStateContext } from "../../context/dataContext";
import { device } from "../../util/device";

import resultsAudio from "../../assets/audio/results-full.mp3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

import { url } from "../../util/config.json";

const StyledResultsModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    text-align: center;
    overflow: hidden;
    background-color: #fff;
    @media ${device.maxSize} {
        .results-items {
            transform: scale(1.5);
        }
    }
    @media ${device.desktopL} {
        .results-items {
            transform: scale(1.1);
        }
    }
    @media ${device.desktop} {
        .results-items {
            transform: scale(0.83);
        }
    }
    @media ${device.laptopL} {
        .results-items {
            transform: scale(0.77);
        }
    }
    @media ${device.laptop} {
        .results-items {
            transform: scale(0.65);
        }
    }
    @media ${device.tablet} {
        .results-items {
            transform: scale(0.31);
        }
    }
    @media ${device.mobileL} {
        .results-items {
            transform: scale(0.29);
        }
    }
`;

const ResultsContainer = styled(motion.div)`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${wishBackground}) center no-repeat;
    background-size: cover;
`;

const ResultsItems = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 1100px;

    list-style-type: none;
    z-index: 2;
`;

const ResultsItem = styled(motion.li)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 500px;
`;

const BannerIMG = styled.img`
    width: 130px;
`;

const IMGContainer = styled.div`
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 120px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ItemIMG = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    &.character {
        transform: translate(-53%, -50%);
        width: 800px;
    }
    &.weapon {
        transform: translate(-47%, -50%);
        width: 150px;
        height: 170px;
    }
`;

const ItemAttrIMG = styled.img`
    pointer-events: none;
    width: 70px;
    margin-bottom: 12px;
`;

const ItemName = styled.p`
    font-size: 14px;
    color: #000;
    line-height: 18px;
`;
const Stars = styled.div`
    width: 100%;
`;

const Rating = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%);
    width: 100%;
    font-size: 15px;
    color: #ffcd2e;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
`;

const UID = styled.a`
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px 50px;
    text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.2);
    @media ${device.tablet} {
        padding: 5px 40px;
        font-size: 10px;
    }
    @media ${device.mobileL} {
        padding: 5px 25px;
        font-size: 6px;
    }
    &:hover {
        color: ${(props) => props.theme.text};
    }
`;

const ResultsModal = ({ backButton, playAudio }) => {
    const { t } = useTranslation();

    const { results } = useDataStateContext();

    useEffect(() => {
        playAudio(resultsAudio);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const variants = {
        hidden: { opacity: 0, x: 600 },
        show: (i) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.12 + 0.2,
                duration: 0.3,
                ease: "circOut",
            },
        }),
    };

    return (
        <StyledResultsModal>
            <ResultsContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7, ease: "easeInOut" }}
            >
                <ResultsItems className="results-items">
                    {results.rolledItems &&
                        results.rolledItems
                            .sort((item1, item2) => item2.rating - item1.rating)
                            .map(({ name, rating, type, element, src }, i) => (
                                <ResultsItem
                                    key={i}
                                    custom={i}
                                    initial="hidden"
                                    animate="show"
                                    variants={variants}
                                >
                                    <BannerIMG
                                        src={require(`../../assets/images/ui/banner-${rating}star.png`)}
                                    />
                                    <IMGContainer>
                                        <ItemIMG
                                            className={type}
                                            // src={require(`../../assets/images/${type}/${src}`)}
                                            src={`${url}/images/${type}/${src}`}
                                            draggable="false"
                                        />
                                        <Rating>
                                            {type === "character" && (
                                                <ItemAttrIMG
                                                    draggable="false"
                                                    src={require(`../../assets/images/element/${element}.png`)}
                                                />
                                            )}
                                            <ItemName>{t(name)}</ItemName>
                                            <Stars>
                                                {Array.apply(
                                                    0,
                                                    Array(rating)
                                                ).map((star, i) => (
                                                    <FontAwesomeIcon
                                                        icon={faStar}
                                                        key={i}
                                                        className="fas fa-star"
                                                    />
                                                ))}
                                            </Stars>
                                        </Rating>
                                    </IMGContainer>
                                </ResultsItem>
                            ))}
                </ResultsItems>
                <CloseButton
                    /**!Move dispatch to top app level later */
                    onClick={() => backButton()}
                />
                <UID
                    // href="https://thekima.com"
                    target="_blank"
                >
                    UID: MIWOJU'S SIMULATOR
                </UID>
            </ResultsContainer>
        </StyledResultsModal>
    );
};

export default ResultsModal;
