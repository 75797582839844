import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

import { device } from "../../util/device";
import { url } from "../../util/config.json";

import { useTranslation } from "react-i18next";

// import ReactStars from "react-rating-stars-component";
// const characterDetailsThumbs = require.context(
//     "../../assets/images/character/character-icons"
// );
const characterDetailsThumbs = `${url}/images/character/character-icons`;

const ItemContainer = styled.div`
    /* width: 100%; */
    /* width: 700px; */
    margin: 0 auto;

    @media ${device.maxSize} {
        width: 900px;
    }
    @media ${device.desktopL} {
        width: 900px;
    }
    @media ${device.desktop} {
        width: 900px;
    }
    @media ${device.laptopL} {
        width: 700px;
    }
    @media ${device.tablet} {
        width: 90%;
    }
    @media ${device.mobileL} {
        width: 100%;
    }

    .highlight {
        font-weight: 500;
        color: #363636;
    }
    .highlight-purple {
        /* color: #b459ff; */
    }
    .highlight-brown {
        /* color: #e97731; */
    }

    .three-star {
        /* border: 2px solid #d0cfcd; */
        background-color: #e2e1df;
    }
    .four-star {
        /* border: 2px solid #b459ff; */
        background-color: rgba(180, 89, 255, 0.95);
    }
    .five-star {
        /* border: 2px solid #e97731; */
        background-color: rgba(233, 119, 49, 0.95);
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 170px 1fr 130px 130px;
    align-items: center;
    place-items: center;
    height: 90px;
    /* background-color: #fff; */
    margin: 18px 0;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    background-color: #fafafa;
    @media ${device.tablet} {
        /* width: 90%; */
    }
    @media ${device.mobileL} {
        grid-template-columns: 1fr 2fr 1fr 1fr;
    }

    ${(props) =>
        props.hover &&
        `
        transition: all 0.2s ease-out;
        transform: scale(1.03);
        padding: 10px 0;
        box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
        `}
`;

const GridItem = styled.div`
    img {
        width: 64px;
        &.weapon {
            width: 50px;
        }
    }
    .fa-star {
        font-size: 16px;
        margin: 0 1px;
        color: #ffcd2e;
        margin-top: 6px;
    }
    p {
        font-weight: 400;
        color: #4e4e4e;
        font-size: 20px;
    }
`;

// const weaponDetailsThumbs = require.context("../../assets/images/weapon");
const weaponDetailsThumbs = `${url}/images/weapon`;

export default function ListView({ item, key }) {
    const [hover, setHover] = useState(false);
    const { t } = useTranslation();

    const { name, type, rating, src, quantity, imgType } = item;
    return (
        <ItemContainer>
            <Grid
                hover={hover}
                onMouseEnter={() => setHover(rating)}
                onMouseLeave={() => setHover(false)}
                className={
                    rating === 4
                        ? "four-star"
                        : rating === 5
                        ? "five-star"
                        : "three-star"
                }
            >
                <GridItem>
                    <img
                        className={type === "weapon" ? "weapon" : ""}
                        src={
                            type === "weapon"
                                ? `${weaponDetailsThumbs}/${src}`
                                : `${characterDetailsThumbs}/${name.toLowerCase()}.${
                                      imgType === "webp" ? "webp" : "png"
                                  }`
                        }
                        alt="item"
                    />
                </GridItem>
                <GridItem>
                    <p
                        className={`
                            highlight
                        ${
                            rating === 4
                                ? " highlight-purple"
                                : rating === 5
                                ? " highlight-brown"
                                : ""
                        }
                        `}
                    >
                        {t(name)}
                    </p>
                    {Array.apply(0, Array(rating)).map((star, i) => (
                        <FontAwesomeIcon
                            icon={faStar}
                            key={i}
                            className="fas fa-star"
                        />
                    ))}
                </GridItem>
                <GridItem
                    style={{
                        placeSelf: "start",
                        alignSelf: "center",
                        marginLeft: "80px",
                    }}
                >
                    {type === "weapon" ? (
                        <p>R{quantity < 5 ? quantity : 5}</p>
                    ) : (
                        <p>C{quantity < 7 ? quantity - 1 : 6}</p>
                    )}
                </GridItem>
                <GridItem>
                    <p>
                        x{" "}
                        <span
                            className={
                                rating === 4
                                    ? "highlight-purple"
                                    : rating === 5
                                    ? "highlight-brown"
                                    : ""
                            }
                        >
                            {quantity}
                        </span>
                    </p>
                </GridItem>
            </Grid>
        </ItemContainer>
    );
}
