import React from "react";
import styled from "styled-components";

import closeButton from "../assets/images/ui/close-button.png";

import wishClose from "../assets/audio/wish-close.mp3";

import { device } from "../util/device";

const StyledCloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 40px;
    display: grid;
    place-items: center;
    background-color: #aebbbf;
    border-radius: 50px;
    ${(props) => props.theme.shadow};
    &:hover {
        border-color: #fff;
        box-shadow: ${(props) => props.theme.boxLight};
    }

    @media ${device.maxSize} {
        width: 56px;
        height: 56px;
        .close-button-img {
            width: 46px;
            height: 46px;
        }
    }
    @media ${device.desktopL} {
        width: 56px;
        height: 56px;
        .close-button-img {
            width: 46px;
            height: 46px;
        }
    }
    @media ${device.desktop} {
        width: 44px;
        height: 44px;
        .close-button-img {
            width: 38px;
            height: 38px;
        }
    }
    @media ${device.laptopL} {
        width: 40px;
        height: 40px;
        .close-button-img {
            width: 34px;
            height: 34px;
        }
    }
    @media ${device.tablet} {
        width: 30px;
        height: 30px;
        .close-button-img {
            width: 24px;
            height: 24px;
        }
    }
    @media ${device.mobileL} {
        right: 15px;
        top: 15px;
        width: 24px;
        height: 24px;
        .close-button-img {
            width: 21px;
            height: 21px;
        }
    }
`;

const CloseButtonIMG = styled.img`
    position: absolute;
    box-sizing: content-box;
    border-radius: 50px;
    border: 0px solid #fff;
    transition: 0.1s border ease-in-out;
    &:hover {
        border-width: 4px;
    }
    /* border: 4px solid #aebbbf;

    width: 36px;
    @media ${device.tablet} {
        width: 44px;
        height: 44px;
    }*/
`;

const CloseButton = ({ onClick }) => {
    const closeClick = () => {
        const sound = document.getElementById("wish-close");
        sound.currentTime = 0;
        sound.play();
    };
    return (
        <>
            <StyledCloseButton onClick={onClick}>
                <CloseButtonIMG
                    className="close-button-img"
                    onClick={() => closeClick()}
                    draggable="false"
                    src={closeButton}
                ></CloseButtonIMG>
                {/*eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio id="wish-close" src={wishClose} />
            </StyledCloseButton>
        </>
    );
};

export default CloseButton;
