import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import { motion, AnimatePresence } from "framer-motion";
// import background4 from "../../assets/images/ui/background4.png";
import wishBackground from "../../assets/images/ui/wish-background.jpeg";
import { useDataStateContext } from "../../context/dataContext";

import { useGlobalDispatchContext } from "../../context/globalContext";

import { device } from "../../util/device";

import { url } from "../../util/config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faStar } from "@fortawesome/free-solid-svg-icons";

// import reveal3Star from "../../assets/audio/3star-reveal.mp3";
// import reveal4Star from "../../assets/audio/4star-reveal.mp3";
// import reveal5Star from "../../assets/audio/5star-reveal.mp3";

import { useTranslation } from "react-i18next";

const StyledResultsSingleModal = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: url(${wishBackground}) center no-repeat;
    background-size: cover;
    overflow: hidden;
`;

const ResultsContainer = styled(motion.div)`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ItemIMG = styled(motion.img)`
    pointer-events: none;
    z-index: 2;
    &.character {
        width: 135%;
    }
    &.weapon {
        width: 20%;
    }
`;

const ItemInfo = styled(motion.div)`
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 35%;
    /* width: 70%; */
    @media ${device.tablet} {
        bottom: 45%;
    }
    @media ${device.mobileL} {
        bottom: 45%;
    }
    .item-details {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .item-name-rating {
            position: absolute;
            margin-top: 22px;
            left: 90px;
            @media ${device.laptopL} {
                left: 90px;
            }
            @media ${device.laptop} {
                left: 90px;
            }
            @media ${device.tablet} {
                left: 50px;
            }
            @media ${device.mobileL} {
                left: 50px;
            }
            .item-name {
                margin-bottom: 10px;
                font-size: 40px;
                color: #fff;
                text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
                /* white-space: nowrap; */
                @media ${device.laptopL} {
                    font-size: 40px;
                }
                @media ${device.laptop} {
                    font-size: 30px;
                }
                @media ${device.tablet} {
                    font-size: 18px;
                }
                @media ${device.mobileL} {
                    font-size: 10px;
                }
            }
        }
    }
`;

const ItemAttrIMG = styled.img`
    pointer-events: none;
    width: 110px;
    @media ${device.laptopL} {
        width: 110px;
    }
    @media ${device.laptop} {
        width: 110px;
    }
    @media ${device.tablet} {
        width: 50px;
    }
    @media ${device.mobileL} {
        width: 30px;
    }
`;

const Stars = styled.div`
    display: flex;
    width: 100%;
    font-size: 20px;
    color: #fcffb0;
    .fa-star {
        margin-left: 3px;
    }
    @media ${device.laptopL} {
        font-size: 20px;
    }
    @media ${device.laptop} {
        font-size: 18px;
    }
    @media ${device.tablet} {
        font-size: 12px;
        .fa-star {
            margin: 0;
        }
    }
    @media ${device.mobileL} {
        font-size: 8px;
        .fa-star {
            margin: 0;
        }
    }
`;

const ItemBackdrop = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vw;
`;

const SkipButton = styled.button`
    position: absolute;
    font-size: 26px;
    letter-spacing: -0.8px;
    color: #fafafa;
    right: 80px;
    top: 30px;
    border: none;
    background-color: transparent;
    z-index: 100;
    padding: 5px 10px;
    border: solid transparent;
    border-width: 2px 0 2px 0;
    .fa-caret-right {
        margin-left: 5px;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border: solid rgba(255, 255, 255, 0.2);
        border-width: 2px 0 2px 0;
    }
    @media ${device.tablet} {
        font-size: 22px;
        top: 20px;
        right: 30px;
    }
    @media ${device.mobileL} {
        font-size: 20px;
        top: 20px;
        right: 20px;
    }
`;

const UID = styled.a`
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px 50px;
    text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.2);
    @media ${device.tablet} {
        padding: 5px 40px;
        font-size: 10px;
    }
    @media ${device.mobileL} {
        padding: 5px 25px;
        font-size: 6px;
    }
    &:hover {
        color: ${(props) => props.theme.text};
    }
`;

const ResultsSingleModal = ({ playWishRevealAudio }) => {
    const { t } = useTranslation();

    const [currentItem, setCurrentItem] = useState(0);
    const globalDispatch = useGlobalDispatchContext();

    const {
        results: { rolledItems },
    } = useDataStateContext();
    const { name, rating, type, element, src, rollId } = rolledItems[
        currentItem
    ];

    const handleClick = useCallback(() => {
        if (currentItem < 9 && rolledItems.length > 1) {
            setCurrentItem(currentItem + 1);
        } else {
            setCurrentItem(0);
            globalDispatch({ type: "SET_DISPLAY", payload: "results-full" });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem, globalDispatch]);

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === "Enter") {
                handleClick();
            }
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [currentItem, globalDispatch]
    );

    useEffect(() => {
        playWishRevealAudio(
            rating === 3
                ? "reveal-3star"
                : rating === 4
                ? "reveal-4star"
                : "reveal-5star"
        );
        //!Disabled for now, causes issues when spamming Enter.
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem, handleClick]);

    const itemDetailVariants = {
        hidden: { opacity: 0, width: "85%" },
        show: {
            opacity: 1,
            width: "95%",
            transition: {
                delay: 1.2,
                duration: 0.5,
                ease: "circOut",
                staggerChildren: 2,
            },
        },
    };
    const itemNameVariants = {
        hidden: { opacity: 0.1 },
        show: {
            opacity: 1,
            transition: {
                delay: 0.8,
                duration: 0.2,
                ease: "easeIn",
            },
        },
    };

    const starVariants = {
        hidden: { opacity: 0, scale: 6, color: "#fcffb0" },
        show: (i) => ({
            opacity: 1,
            scale: 1,
            color: "#fef92d",
            transition: {
                delay: i * 0.15 + 2,
                duration: 0.4,
                ease: "circOut",
            },
        }),
    };

    return (
        <StyledResultsSingleModal onClick={() => handleClick()}>
            <AnimatePresence exitBeforeEnter>
                <ResultsContainer
                    key={rollId}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    <SkipButton
                        onClick={() =>
                            globalDispatch({
                                type: "SET_DISPLAY",
                                payload: "results-full",
                            })
                        }
                    >
                        {t("Skip")}
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className="fas fa-caret-right"
                        />
                    </SkipButton>
                    {rating === 5 && (
                        <ItemBackdrop
                            initial={{
                                opacity: 0,
                                background:
                                    "radial-gradient( rgb(255, 229, 72) 10%, rgb(255, 252, 197) 30%, rgba(255, 132, 64, 0.1) 100%)",
                            }}
                            animate={{
                                opacity: [1, 1, 1],
                                background: [
                                    "radial-gradient( rgb(255, 229, 72) 30%, rgb(255, 252, 197) 50%, rgba(255, 132, 64, 0.1) 100%)",
                                    "radial-gradient( rgb(255, 229, 72) 30%, rgb(255, 252, 197) 50%, rgba(255, 132, 64, 0.1) 100%)",
                                    "radial-gradient( rgb(255, 132, 64) 15%, rgba(255, 229, 72, 0.2) 50%, rgba(255, 132, 64, 0.1) 90%)",
                                ],
                            }}
                            transition={{
                                duration: 0.8,
                                delay: 0.15,
                                times: [0, 0.2, 1],
                            }}
                        />
                    )}
                    {rating === 4 && (
                        <ItemBackdrop
                            initial={{
                                opacity: 0,
                                background:
                                    "radial-gradient( rgb(223, 108, 255) 0%, rgba(78, 37, 173, 0.5) 30%, rgba(90, 50, 169, 0.1) 70%)",
                            }}
                            animate={{
                                opacity: [1, 1, 1],
                                background: [
                                    "radial-gradient( rgba(223, 108, 255, 0.6) 0%, rgba(78, 37, 173, 0.6) 30%, rgba(90, 50, 169, 0.1) 70%)",
                                    "radial-gradient( rgba(223, 108, 255, 0.6) 0%, rgba(78, 37, 173, 0.6) 30%, rgba(90, 50, 169, 0.1) 70%)",
                                    "radial-gradient( rgb(78, 37, 173) 0%, rgba(78, 37, 173, 0.5) 20%, rgba(90, 50, 169, 0.1) 50%)",
                                ],
                            }}
                            transition={{
                                duration: 0.6,
                                delay: 0.15,
                                times: [0, 0.2, 1],
                            }}
                        />
                    )}
                    {rating === 3 && (
                        <ItemBackdrop
                            initial={{
                                opacity: 0,
                                background:
                                    "radial-gradient( rgb(36,81,183) 0%, rgba(38,78,170, 0.8) 10%, rgba(90, 50, 169, 0.1) 25%)",
                            }}
                            animate={{
                                opacity: [1, 1, 1],
                                background: [
                                    "radial-gradient( rgb(36,81,183) 0%, rgba(38,78,170, 0.8) 10%, rgba(90, 50, 169, 0.1) 25%)",
                                    "radial-gradient( rgb(36,81,183) 0%, rgba(38,78,170, 0.8) 10%, rgba(90, 50, 169, 0.1) 25%)",
                                    "radial-gradient( rgb(38,78,170) 0%, rgba(36,81,183, 0.5) 20%, rgba(90, 50, 169, 0.1) 35%)",
                                ],
                            }}
                            transition={{
                                duration: 0.6,
                                delay: 0.15,
                                times: [0, 0.2, 1],
                            }}
                        />
                    )}
                    <ItemIMG
                        key={rollId}
                        initial={{
                            x: 0,
                            scale: 4,
                            filter: "brightness(0%)",
                        }}
                        animate={{
                            scale: [4, 1, 1, 1.02],
                            x: [0, 0, 0, 20],
                            filter: [
                                "brightness(0%)",
                                "brightness(0%)",
                                "brightness(0%)",
                                "brightness(100%)",
                            ],
                        }}
                        transition={{
                            ease: "circOut",
                            duration: 1.6,
                            times: [0, 0.2, 0.77, 1],
                        }}
                        className={type}
                        draggable="false"
                        // src={
                        //     require(`../../assets/images/${type}/${
                        //         name !== "Xiao" ? src : "Xiao-Splash.png"
                        //     }`).default
                        // }
                        preload={rating === 5 ? "auto" : "none"}
                        src={`${url}/images/${type}/${src}`}
                        // src={require(`../../assets/images/${type}/${src}`)}
                        exit={{
                            opacity: 0,
                            transition: { duration: 0 },
                        }}
                    ></ItemIMG>
                    <ItemInfo
                        initial="hidden"
                        animate="show"
                        variants={itemDetailVariants}
                    >
                        <motion.div
                            className="item-details"
                            variants={itemNameVariants}
                        >
                            {type === "character" && (
                                <ItemAttrIMG
                                    src={require(`../../assets/images/element/${element}.png`)}
                                    draggable="false"
                                />
                            )}
                            <div className="item-name-rating">
                                <p
                                    className="item-name"
                                    style={{
                                        whiteSpace:
                                            type === "character"
                                                ? "nowrap"
                                                : "normal",
                                    }}
                                >
                                    {t(name)}
                                </p>
                                <Stars>
                                    {Array.apply(0, Array(rating)).map(
                                        (star, i) => (
                                            <motion.span
                                                //to prevent awkward movement do to being span
                                                style={{ height: "20px" }}
                                                key={i}
                                                custom={i}
                                                initial="hidden"
                                                animate="show"
                                                variants={starVariants}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faStar}
                                                    className="fas fa-star"
                                                />
                                            </motion.span>
                                        )
                                    )}
                                </Stars>
                            </div>
                        </motion.div>
                    </ItemInfo>
                </ResultsContainer>
            </AnimatePresence>
            <UID
                // href="https://thekima.com"
                target="_blank"
            >
                UID: MIWOJU'S SIMULATOR
            </UID>
        </StyledResultsSingleModal>
    );
};

export default ResultsSingleModal;
