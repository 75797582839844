import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

import { url } from "../../util/config.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { device } from "../../util/device";

import { useTranslation } from "react-i18next";

const StyledWishVideo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 99;
`;
const SkipButton = styled.button`
    position: absolute;
    font-size: 26px;
    letter-spacing: -0.8px;
    color: #fafafa;
    right: 80px;
    top: 30px;
    border: none;
    background-color: transparent;
    z-index: 100;
    padding: 5px 10px;
    border: solid transparent;
    border-width: 2px 0 2px 0;
    .fas {
        margin-left: 5px;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border: solid rgba(255, 255, 255, 0.2);
        border-width: 2px 0 2px 0;
    }
    @media ${device.tablet} {
        font-size: 22px;
        top: 20px;
        right: 30px;
    }
    @media ${device.mobileL} {
        font-size: 20px;
        top: 20px;
        right: 20px;
    }
`;
const Video = styled.video`
    object-fit: cover;
    /* top: 0;
    left: 0; */
`;

const UID = styled.a`
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px 50px;
    text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.2);
    @media ${device.tablet} {
        padding: 5px 40px;
        font-size: 10px;
    }
    @media ${device.mobileL} {
        padding: 5px 25px;
        font-size: 6px;
    }
    &:hover {
        color: ${(props) => props.theme.text};
    }
`;

const WishVideo = () => {
    const { t } = useTranslation();
    const { playClip, loading, volume } = useGlobalStateContext();
    const globalDispatch = useGlobalDispatchContext();
    const [isPlaying, setIsPlaying] = useState(false);

    const threeStarRef = useRef(null);
    const fourStarRef = useRef(null);
    const fourStarTenRef = useRef(null);
    const fiveStarRef = useRef(null);
    const fiveStarTenRef = useRef(null);

    const videoList = {
        threeStar: {
            ref: threeStarRef,
            clip: `${url}/videos/3starwish-single.mp4`,
            // clip: threeStarClip,
        },
        fourStar: {
            ref: fourStarRef,
            clip: `${url}/videos/4starwish-single.mp4`,
            // clip: fourStarClip,
        },
        fourStarTen: {
            ref: fourStarTenRef,
            clip: `${url}/videos/4starwish.mp4`,
        },
        fiveStar: {
            ref: fiveStarRef,
            clip: `${url}/videos/5starwish-single.mp4`,
        },
        fiveStarTen: {
            ref: fiveStarTenRef,
            clip: `${url}/videos/5starwish.mp4`,
        },
    };

    const endVideo = useCallback(() => {
        globalDispatch({
            type: "SET_DISPLAY",
            payload: "results-full",
        });

        setIsPlaying(false);
        globalDispatch({
            type: "SET_CLIP",
            payload: false,
        });

        videoList[playClip].ref.current.pause();
        videoList[playClip].ref.current.currentTime = 0;
    }, [globalDispatch, videoList, playClip]);

    const handleEnd = () => {
        globalDispatch({
            type: "SET_DISPLAY",
            payload: "results-single",
        });
        setIsPlaying(false);
        globalDispatch({
            type: "SET_CLIP",
            payload: false,
        });
    };

    const vidChecker = (vidType) => {
        if (vidType === isPlaying) {
            return { display: "block" };
        } else {
            return { display: "none" };
        }
    };

    useEffect(() => {
        const playVideo = (playClip) => {
            videoList[playClip].ref.current.volume = isNaN(
                volume.backgroundMusic
            )
                ? 0
                : volume.backgroundMusic;

            videoList[playClip].ref.current.play();
        };
        // if (playClip && !loading) {
        //     playVideo(playClip);
        // }
        if (playClip) {
            playVideo(playClip);
        }
    }, [playClip, videoList, loading, volume.backgroundMusic]);

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === "Enter" && isPlaying) {
                endVideo();
            }
        },
        [endVideo, isPlaying]
    );

    useEffect(() => {
        window.addEventListener("keypress", handleKeyPress);
        return () => {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <StyledWishVideo style={{ display: `${isPlaying ? "block" : "none"}` }}>
            <SkipButton onClick={() => endVideo()}>
                {t("Skip")}
                <FontAwesomeIcon
                    icon={faCaretRight}
                    className="fas fa-caret-right"
                />
            </SkipButton>
            {Object.entries(videoList).map(([video, ownProps]) => (
                <Video
                    key={video}
                    //Checks so it can "display: none" if not playing
                    style={vidChecker(ownProps.ref)}
                    onPlay={() => setIsPlaying(ownProps.ref)}
                    onCanPlayThrough={() =>
                        globalDispatch({
                            type: "STOP_LOADING",
                        })
                    }
                    onEnded={() => handleEnd()}
                    ref={ownProps.ref}
                    src={ownProps.clip}
                    preload={
                        ownProps.ref === fourStarTenRef || fiveStarTenRef
                            ? "auto"
                            : "none"
                    }
                    type="video/mp4"
                    height="100%"
                    width="100%"
                    playsInline
                ></Video>
            ))}
            <UID
                // href="https://thekima.com"
                target="_blank"
            >
                UID: MIWOJU'S SIMULATOR
            </UID>
        </StyledWishVideo>
    );
};

export default WishVideo;
