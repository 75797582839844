import React from "react";

//Styled components
import styled from "styled-components";

import backIcon from "../../assets/images/ui/back-button.png";

import Details from "./Details";
import History from "./History";
import Collection from "./Collection";
import InfoContainer from "../infoPageLayout/InfoContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const StyledInfoPage = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    background-size: cover;
    min-height: 100vh;
    background-color: #ebebeb;
    padding-top: 50px;
    width: 100%;
    user-select: none;
    z-index: 99;
`;

const Navbar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #111315;
    padding: 0 20px;
    z-index: 999;
    img {
        box-sizing: content-box;
        padding: 14px;
        width: 26px;
        cursor: pointer;
    }
    .fas {
        color: #fff;
        font-size: 22px;
        /* margin-right: 20px; */
        /* padding: 14px; */
        cursor: pointer;
        &:hover {
            color: #ababab;
        }
    }
`;

const InfoPage = ({ pageId, backButton }) => {
    return (
        <StyledInfoPage>
            <Navbar>
                {/* eslint-disable-next-line  */}
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events*/}
                <img
                    onClick={() => backButton()}
                    src={backIcon}
                    alt="back button"
                />
                <FontAwesomeIcon icon={faRedoAlt} className="fas fa-redo-alt" />
            </Navbar>
            <InfoContainer>
                {pageId === "details" && <Details />}
                {pageId === "history" && <History />}
                {pageId === "collection" && <Collection />}
            </InfoContainer>
        </StyledInfoPage>
    );
};

export default InfoPage;
