import React, { useState } from "react";
import styled from "styled-components";

import notices from "../../assets/data/notices/notices.json";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

const StyledGateway = styled.div`
    color: #495365;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    /* background-color: #fff; */
    background-color: #b3dcee;
    z-index: 2;
    padding: 5px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    .content {
    }
`;

const UpdateNotes = styled.div`
    margin: 15px 0px;
    padding: 5px 5px;
    background-color: #fff;
    border: 1px solid #000;
    text-align: left;
    min-width: 400px;
    div {
        line-height: 1.5;
        padding: 7px 5px;
        min-height: 150px;
        .date {
            font-size: 15px;
            color: #f49c00;
        }
        .message {
            font-size: 14px;
        }
    }
`;

const Form = styled.form`
    text-align: left;
    align-self: flex-start;
    margin: 30px 0px;

    input {
        padding: 7px;
        margin: 5px 5px 5px 0px;
        min-width: 300px;
    }
    button {
        padding: 8px;
        margin-top: 10px;
        border: none;
        font-size: 15px;
        background-color: #fedd03;
    }
    .form-footer {
        text-align: center;
        font-size: 14px;
        margin-top: 30px;
    }
`;

const Gateway = () => {
    const [pass, setPass] = useState("");
    const [submitMessage, setSubmitMessage] = useState("");
    const { ilhani } = useGlobalStateContext();
    const globalDispatch = useGlobalDispatchContext();

    const {
        gateway: { patches },
    } = notices;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pass === ilhani) {
            setSubmitMessage("Success!");
            globalDispatch({
                type: "SET_ACCESS",
                payload: true,
            });
        } else {
            setSubmitMessage("Oops! Wrong code.. please try again.");
        }
    };
    return (
        <StyledGateway>
            <div className="content">
                <p style={{ fontSize: "18px" }}>
                    Welcome to MIWOJU's private Genshin Wish Simulator!
                </p>
                <UpdateNotes>
                    Recent Updates:
                    <div>
                        {patches.map(({ date, message }) => (
                            <p>
                                <span className="date">{date}</span>:{" "}
                                <span className="message">{message}</span>
                            </p>
                        ))}
                    </div>
                </UpdateNotes>
                <Form onSubmit={handleSubmit}>
                    Secret passcode? ^^
                    <br />
                    <input
                        type="text"
                        name="name"
                        onChange={(e) => setPass(e.target.value)}
                        placeholder="Type passcode here..."
                    />
                    <button type="submit" value="Submit">
                        SUBMIT!
                    </button>
                    <p style={{ fontSize: "14px", color: "red" }}>
                        {submitMessage}
                    </p>
                    <p className="form-footer">
                        If you don't know the passcode:
                        <br />
                        <a
                            // href="https://www.buymeacoffee.com/miwoju/new-update-passcode-added"
                            href="https://www.buymeacoffee.com/miwoju/link-passcode"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* https://www.buymeacoffee.com/miwoju/new-update-passcode-added */}
                            https://www.buymeacoffee.com/miwoju/link-passcode
                        </a>
                    </p>
                </Form>
            </div>
        </StyledGateway>
    );
};

export default Gateway;
